import React, {useEffect, useState} from "react";
import {Button, ConfigProvider, Radio, Select, Tag, Typography} from "antd";
import type {ColumnProps} from "antd/es/table";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {IngredientsTable} from "src/components/IngredientsTable";
import {DownloadButton} from "src/components/DownloadButton";
import {SearchBar} from "src/components/SearchBar";
import {LegendsCard} from "src/components/LegendsCard";
import {PRIMARY_COLOR} from "src/constants/colorContants";
import {getCachedIngredients} from "src/api/cache/ingredientsCache";
import {Acceptability, Ingredient} from "src/types/ingredient";
import {searchIngredients} from "src/api/searchApi";
import {CSV, PDF} from "src/constants/fileType";
import {TermsCard} from "src/components/TermsCard";
import BottomTermsCard from "src/components/BottomTermsCard";
import {alphabets, getAcceptabilityIcon, getColumnValue} from "src/utils/containers/SearchContainerUtils";
import {getName, sortItems} from "src/utils/commonUtils";
import {
    ACCEPTABILITY,
    ADMIN_PAGE,
    adminColumnList,
    HOME_PAGE,
    INGREDIENT,
    SelectModes,
    visibilities
} from "src/constants/appConstants";
import {filterIngredients} from "src/utils/types/ingredientUtils";
import Delete from "src/components/common/Delete";
import {searchContainerAddIngredientButtonStyle, searchFiltersStyle} from "src/constants/styleConstants";
import "../css/style.css";
import {proximitySort} from "src/utils/CustomSorts";
import {getMetricsPublisher, MetricKey} from "src/utils/metrics";

const {Option} = Select;

export let filteredIdsForApi = new Set<string>();

interface SearchContainerProps {
    admin: boolean;
}

const SearchContainer: React.FC<SearchContainerProps> = ({admin}) => {
    const initialTime = Date.now();
    useEffect(() => {
        const metricsPublisher = getMetricsPublisher();
        if (admin) {
            metricsPublisher.publishTimerMetric(ADMIN_PAGE,
              MetricKey.PAGE_LOAD_LATENCY, Date.now() - initialTime);
            metricsPublisher.publishCounterMetric(ADMIN_PAGE, MetricKey.CLICKS, 1);
        } else {
            metricsPublisher.publishTimerMetric(HOME_PAGE,
              MetricKey.PAGE_LOAD_LATENCY, Date.now() - initialTime);
            metricsPublisher.publishCounterMetric(HOME_PAGE, MetricKey.CLICKS, 1);
        }
    }, [admin]);
    const navigate = useNavigate();
    const [ingredients, setIngredients] = useState<Ingredient[]>([]);
    const [ingredientDetails, setIngredientDetails] = useState<Ingredient>();
    // SearchBar
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchedValues, setSearchedValues] = useState<string[]>([])
    const [searchSuggestionsArray, setSearchSuggestionsArray] = useState<Ingredient[]>([]);
    const [searchResultsArray, setSearchResultsArray] = useState<Ingredient[]>([]);
    const [exactSearchResultsArray, setExactSearchResultsArray] = useState<Ingredient[]>([]);
    const [searchResults,setSearchResults]=useState<any>()
    // Ingredients Table Headers
    const [typeColumns, setTypeColumns] = useState<Map<string, ColumnProps<Ingredient>>>(new Map());
    // Admin Table Header
    const [adminColumns, setAdminColumns] = useState<Map<string, ColumnProps<Ingredient>>>(new Map());
    // Filters
    const [authors, setAuthors] = useState<string[]>([])
    const [authorFilters, setAuthorFilters] = useState<string[]>([])
    const [types, setTypes] = useState<string[]>([])
    const [filterTypes, setFilterTypes] = useState<string[]>([])
    const [classifications, setClassifications] = useState<string[]>([]);
    const [classificationFilters, setClassificationFilters] = useState<string[]>([]);
    const [visibilityFilters, setVisibilityFilters] = useState<string[]>([]);
    // Alphabet Button Group
    const [filterByCharValues, setFilterByCharValues] = useState<string[]>([])
    // Fetch ingredients from query
    const [queryParams] = useSearchParams();
    const [ingredientIsLoading, setIngredientLoading] = useState<boolean>(true);
    // Delete Ingredient
    const [isDeleteIngredientModalVisible, setDeleteIngredientModalVisible] = useState(false);


    useEffect(() => {
        const fetchSearchSuggestions = async () => {
            setIngredientLoading(true);
            try {
                if (searchValue.length > 2) {
                    const searchSug: string[] = [];
                    searchSug.push(searchValue);
                    const searchSuggestions = await searchIngredients(searchSug);
                    let flexibleSearchSuggestions: Ingredient[] = searchSuggestions.flexibleMatchResults[searchValue] || [];

                    // Filter suggestions based on visibility for non-admin users
                    flexibleSearchSuggestions = admin
                      ? flexibleSearchSuggestions
                      : flexibleSearchSuggestions.filter(ingredient => ingredient.visibility === 'Publish');

                    flexibleSearchSuggestions = flexibleSearchSuggestions.filter(
                        (ingredient, index, self) =>
                            index === self.findIndex((i) => i.id === ingredient.id)
                    );

                    setSearchSuggestionsArray(flexibleSearchSuggestions);
                }
            } catch (error) {
                console.error('Error fetching search results:', error);
            } finally {
                setIngredientLoading(false);}
        };
        fetchSearchSuggestions();

        // Cleanup function to set the flag to false
        return () => {};
    }, [searchValue]);


    useEffect(() => {
        const fetchSearchResults = async () => {
            setIngredientLoading(true);
            try {
                if (searchedValues.length > 0) {
                    const searchResults = await searchIngredients(searchedValues);

                    setSearchResults(searchResults)

                    const searchResultsArray: Ingredient[] = [];
                    const exactSearchResultsArray: Ingredient[]= [];
                    for (const searchValue of searchedValues) {
                        const exactSearchResults: Ingredient[] = searchResults.exactMatchResults[searchValue] || [];
                        const flexibleSearchResults: Ingredient[] = searchResults.flexibleMatchResults[searchValue] || [];

                        // Filter results based on visibility for non-admin users
                        const filteredExactResults = admin ? exactSearchResults : exactSearchResults.filter(ingredient => ingredient.visibility === 'Publish');
                        const filteredFlexibleResults = admin ? flexibleSearchResults : flexibleSearchResults.filter(ingredient => ingredient.visibility === 'Publish');

                        const SearchArray: Ingredient[] = [...filteredExactResults, ...filteredFlexibleResults];
                        exactSearchResultsArray.push(...filteredExactResults);
                        searchResultsArray.push(...SearchArray);
                    }

                    setExactSearchResultsArray(exactSearchResultsArray)
                    setSearchResultsArray(searchResultsArray)
                }

            } catch (error) {
                console.error('Error fetching search results:', error);
            } finally {
                setIngredientLoading(false);
            }
        };
        fetchSearchResults();
    }, [searchedValues]);


    // Fetch ingredients from API and Cache
    useEffect(() => {
        setIngredientLoading(true);
        getCachedIngredients()
            .then(cachedIngredients => {
                const listOfIngredients = admin ? cachedIngredients : cachedIngredients.filter(ingredient => ingredient.visibility === 'Publish');
                setIngredients(listOfIngredients);
                setIngredientLoading(false);
                // All types in acceptability, creates respective column entry
                const types = new Map<string, ColumnProps<Ingredient>>();
                const classifications = new Set<string>();
                const authorList = new Set<string>();
                const adminColumns = new Map<string, ColumnProps<Ingredient>>();
                cachedIngredients.forEach(({classification, acceptability, createdBy}) => {
                    if(classification && classification.trim().length > 0)
                        classifications.add(classification);
                    if (createdBy && createdBy.trim().length > 0)
                        authorList.add(getName(createdBy));
                    acceptability.forEach(a => {
                        if(a.type) {
                            types.set(a.type, {
                                title: a.type,
                                dataIndex: ACCEPTABILITY,
                                key: a.type,
                                render: (value: Acceptability[]) => {
                                    const acceptability = value.find((v: Acceptability) => v.type == a.type)
                                    return getAcceptabilityIcon(acceptability)
                                }
                            })
                        }
                    })
                });
                adminColumnList.forEach(({columnName, columnMapping}) => {
                    const column: ColumnProps<Ingredient> = {
                        title: columnName,
                        dataIndex: columnName,
                        key: columnMapping,
                        render: (_value, record) => <>{getColumnValue(record, columnMapping)}</>
                    };
                    adminColumns.set(columnName, column);
                })
                setTypeColumns(types);
                setTypes(Array.from(types.keys()));
                setFilterTypes(Array.from(types.keys()));
                setClassifications(Array.from(classifications));
                setClassificationFilters(Array.from(classifications));
                setAuthors(Array.from(authorList));
                setAuthorFilters(Array.from(authorList));
                setVisibilityFilters(visibilities);
                setAdminColumns(adminColumns);
                if(!window.location.href.includes("?ingredients=")) {
                    setSearchedValues([]);
                    setSearchValue("");
                }
                setFilterByCharValues([]);
            });
    }, [location.pathname, window.location.href])

    // Fetch query parameters for ingredients from URL
    useEffect(() => {
        const searchedIngredients = queryParams.get('ingredients');
        setSearchedValues(searchedIngredients?.split(',') || [])
    }, [queryParams])

    // Remaining Columns
    const remainingColumns = admin ? adminColumns : typeColumns;

    // column headers for table
    let columns: ColumnProps<Ingredient>[] = [
        {
            title: 'Ingredients',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            sorter: sortItems,
            render: (value, record) =>
                <>
                {
                    admin ? (
                        <>
                            <Tag>{record.classification}</Tag>
                            <p>{value}</p>
                            <Button type="link"><Link to={`edit/ingredient/${record.id}`}>Edit</Link></Button>
                            <ConfigProvider
                                theme={{
                                    token: {
                                        // Seed Token
                                        colorLink : '#00b966',
                                    },
                                }}
                            >
                                <Button type="link"><Link
                                    to={`/?ingredients=${record.name}`}>View</Link></Button>
                            </ConfigProvider>
                            <Button type="link" danger onClick={() => {setIngredientDetails(record); setDeleteIngredientModalVisible(true)}}>Delete</Button>
                        </>
                    ) : (
                        <><Tag>{record.classification}</Tag><p>{value}</p></>
                    )
                }
                </>
        },
        ...Array.from(remainingColumns.values())
    ];

    const [filteredColumns, setFilteredColumns] = useState(columns);

    // Filter column entries by types
    useEffect(() => {
         setFilteredColumns(columns.filter(c => String(c.key) == 'name' || filterTypes.includes(String(c.key))))
    }, [filterTypes, filterByCharValues, searchedValues])

    const resetFilters = () => {
        setClassificationFilters([]);
    }

    const handleDelete = () => {
        setDeleteIngredientModalVisible(false);
        setSearchedValues([]);
        navigate(HOME_PAGE);
        navigate(0);
    }

    const handleSelectChange = (values: any) => {
        // Update the selected value state
        setSearchedValues(values);
        setSearchValue("");
    }

    const tableColumns = admin ? columns : filteredColumns;

    const handleOnSearchValue=(value: string)=>{

        const trimmedValue = value.trim();

        const hasCommaWithNonNumericBefore = /[^0-9]\s*,/;
        const regexToSplit = /(?<!\d),/;

        if (hasCommaWithNonNumericBefore.test(trimmedValue)) {
                const filteredValues = trimmedValue
                    .split(regexToSplit)
                    .map(v => v.trim())
                    .filter(v => v.length > 2);

                if (filteredValues.length > 0) {
                    setSearchedValues([...searchedValues, ...filteredValues]);
                }

                setSearchValue("");
                return;
        }
        setSearchValue(value);
    }

    return <>
        <h3>Search one or more ingredients for detailed results</h3>
        <div className={"search-container-header"}>
            <div>
                <SearchBar
                    data={
                        (searchValue.length > 2 ? searchSuggestionsArray : ingredients)
                            .sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true,}))
                            .map(ingredient => <Option key={`${ingredient.name}-${ingredient.id}`}
                                                       value={`${ingredient.name}`}>{ingredient.name}</Option>)
                    }
                    searchValue={searchValue}
                    searchedValues={searchedValues}
                    onChange={handleSelectChange}
                    onSearch={handleOnSearchValue}
                    onInputKeyDown={e => {
                        if (e.key === 'Enter') {
                            e.stopPropagation()
                            // @ts-ignore
                            if (e.target.value.length > 0) {
                                // @ts-ignore
                                if (searchValue.trim().length > 2) {
                                    setSearchedValues([...searchedValues, searchValue.trim()]);
                                }
                                setSearchValue("");
                            }
                        }
                    }}
                    tagRender={props => {
                        const {value, closable, onClose} = props;
                        const [tagColor, setTagColor] = useState(PRIMARY_COLOR);
                        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
                            event.preventDefault();
                            event.stopPropagation();
                        };

                        return (
                            <Tag
                                color={tagColor}
                                onMouseDown={onPreventMouseDown}
                                closable={closable}
                                onClose={onClose}
                            >
                                {value}
                            </Tag>
                        );
                    }
                    }
                />
                <div className={"margin-default"}>
                    <Typography.Text>{`Filter results by ${admin ? "author" : "category"}: `}</Typography.Text>
                    <Select
                        mode={SelectModes.multiple}
                        placeholder={`Filter ${admin ? "author" : "category"}: `}
                        style={searchFiltersStyle}
                        value={admin ? authorFilters : filterTypes}
                        onChange={(values) => admin ? setAuthorFilters(values) : setFilterTypes(values)}
                        allowClear>
                        {
                            admin ? authors
                                    .sort((a, b) => a.localeCompare(b, undefined, {numeric: true,}))
                                    .map(author => <Option key={author}>{author}</Option>)
                                : Array.from(typeColumns.keys())
                                    .sort((a, b) => a.localeCompare(b, undefined, {numeric: true,}))
                                    .map(type => <Option key={type}>{type}</Option>)
                        }
                    </Select>
                    <br/>
                    <Typography.Text>Filter results by classifications: </Typography.Text>
                    <Select
                        mode={SelectModes.multiple}
                        placeholder="Included classifications"
                        style={searchFiltersStyle}
                        value={classificationFilters}
                        onChange={(values) => setClassificationFilters(values)}
                        allowClear>
                        {classifications
                            .sort((a, b) => a.localeCompare(b, undefined, {numeric: true,}))
                            .map(classification => <Option key={classification}>{classification}</Option>)}
                    </Select>
                    <br/>
                    {admin &&
                        <>
                            <Typography.Text>Filter results by visibility: </Typography.Text>
                            <Select
                                mode={SelectModes.multiple}
                                placeholder="Filter visibility"
                                style={searchFiltersStyle}
                                value={visibilityFilters}
                                onChange={(values) => setVisibilityFilters(values)}
                                allowClear>
                                {visibilities.map(visibility => <Option key={visibility}>{visibility}</Option>)}
                            </Select>
                        </>
                    }
                </div>
                <div className={"margin-default"}>
                    <Radio.Group
                        buttonStyle="solid"
                        value={filterByCharValues.length > 0 ? (!isNaN(Number(filterByCharValues[0].charAt(0))) ? '#' : filterByCharValues[0].charAt(0).toUpperCase()) : ""}
                    >
                        {
                            alphabets.map(char => <Radio.Button key={char} value={char}
                                onClick={()=>{

                                    setSearchValue("");
                                    setSearchedValues([]);
                                }
                                }>{char}</Radio.Button>)}
                        <Radio.Button onClick={() => {
                            console.log(classifications);
                            setSearchedValues([]);
                            setSearchValue("");
                            setAuthorFilters(authors);
                            setFilterTypes(types);
                            setClassificationFilters(classifications)
                        }}>Reset Search Filters</Radio.Button>
                    </Radio.Group>
                </div>
                {
                    admin ? (
                        <></>
                    ) : (
                        <div className={"margin-default"}>
                            <TermsCard/>
                        </div>
                    )
                }
            </div>
            {
                admin ? (
                    <div className={"search-container-add-ingredient"}>
                        <Button type="primary"><Link to={"add/ingredient"}
                                                     style={searchContainerAddIngredientButtonStyle}>Add</Link></Button>
                    </div>) : (
                    <div className={"min-width-default"}>
                        <LegendsCard/>
                    </div>
                )
            }
        </div>
        <div className="search-result-container">
            <h3>Search Results:</h3>
        </div>
        <IngredientsTable
            data={
                proximitySort(
                    filterIngredients(
                        searchedValues.length > 0 ? searchResultsArray : ingredients,
                        classificationFilters,
                        authorFilters,
                        visibilityFilters,
                        filterTypes
                    ),
                    searchedValues
                )}
            columns={tableColumns}
            loading={ingredientIsLoading}
            admin={admin}/>
        {
            admin ? (
                <></>
            ) : (
                <div className={"search-container-download-file"}>
                    <Typography.Text>Download results as:</Typography.Text>
                    <DownloadButton ids={Array.from(filteredIdsForApi.values())} filteredCategory={filterTypes} fileType={CSV}/>
                    <DownloadButton ids={Array.from(filteredIdsForApi.values())} filteredCategory={filterTypes}  fileType={PDF}/>
                </div>
            )
        }
        <div className={"margin-default"}>
            <BottomTermsCard/>
        </div>
        {
            ingredientDetails !== undefined && (
                <Delete
                    open={isDeleteIngredientModalVisible}
                    onConfirm={handleDelete}
                    onCancel={() => setDeleteIngredientModalVisible(false)}
                    type={INGREDIENT}
                    id={ingredientDetails?.id}
                />
            )
        }
    </>
}

export default SearchContainer;