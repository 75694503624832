import React, {useEffect, useState} from "react";
import {Button, ConfigProvider, Select, Tag, Typography} from "antd";
import type {ColumnProps} from "antd/es/table";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {SummaryTable} from "src/components/SummaryTable";
import {SearchBar} from "src/components/SearchBar";
import {PRIMARY_COLOR} from "src/constants/colorContants";
import {getCachedIngredients} from "src/api/cache/ingredientsCache";
import {Acceptability, Ingredient} from "src/types/ingredient";
import {searchIngredients, sendFeedback} from "src/api/searchApi";
import {TermsCard} from "src/components/TermsCard";
import BottomTermsCard from "src/components/BottomTermsCard";
import {getAcceptabilityIcon, getColumnValue} from "src/utils/containers/SearchContainerUtils";
import {getName, sortItems} from "src/utils/commonUtils";
import {Input, Modal} from "antd";
import {
    ACCEPTABILITY,
    ADMIN_PAGE,
    adminColumnList,
    HOME_PAGE,
    INGREDIENT,
    visibilities
} from "src/constants/appConstants";
import {filterIngredient} from "src/utils/types/ingredientUtils";
import Delete from "src/components/common/Delete";
import {searchFiltersStyle} from "src/constants/styleConstants";
import "../css/style.css";
import {getMetricsPublisher, MetricKey} from "src/utils/metrics";
import {message} from "antd";

const {Option} = Select;

export let filteredIdsForApi = new Set<string>();

interface SummaryContainerProps {
    admin: boolean;
}

const SummaryContainer: React.FC<SummaryContainerProps> = ({admin}) => {
    const initialTime = Date.now();
    useEffect(() => {
        const metricsPublisher = getMetricsPublisher();
        if (admin) {
            metricsPublisher.publishTimerMetric(ADMIN_PAGE,
                MetricKey.PAGE_LOAD_LATENCY, Date.now() - initialTime);
            metricsPublisher.publishCounterMetric(ADMIN_PAGE, MetricKey.CLICKS, 1);
        } else {
            metricsPublisher.publishTimerMetric(HOME_PAGE,
                MetricKey.PAGE_LOAD_LATENCY, Date.now() - initialTime);
            metricsPublisher.publishCounterMetric(HOME_PAGE, MetricKey.CLICKS, 1);
        }
    }, [admin]);
    const navigate = useNavigate();
    const [ingredients, setIngredients] = useState<Ingredient[]>([]);
    const [ingredientDetails, setIngredientDetails] = useState<Ingredient>();
    // SearchBar
    const [searchValue, setSearchValue] = useState<string>("");
    const [searchedValues, setSearchedValues] = useState<string[]>([])
    const [searchSuggestionsArray, setSearchSuggestionsArray] = useState<Ingredient[]>([]);
    const [searchResultsArray, setSearchResultsArray] = useState<Ingredient[]>([]);
    const [exactSearchResultsArray, setExactSearchResultsArray] = useState<Ingredient[]>([]);
    const [exactSearchTokenResultsArray, setExactSearchTokenResultsArray] = useState<Ingredient[]>([]);
    const [exactSearchResultsNotFoundArray, setexactSearchResultsNotFoundArray] = useState<string[]>([]);
    const [searchResults,setSearchResults]=useState<any>()
    // Ingredients Table Headers
    const [typeColumns, setTypeColumns] = useState<Map<string, ColumnProps<Ingredient>>>(new Map());
    // Admin Table Header
    const [adminColumns, setAdminColumns] = useState<Map<string, ColumnProps<Ingredient>>>(new Map());
    // Filters
    const [authors, setAuthors] = useState<string[]>([])
    const [authorFilters, setAuthorFilters] = useState<string[]>([])
    const [types, setTypes] = useState<string[]>([])
    const [selectedType, setSelectedType] = useState<string | undefined>(undefined);
    const [classifications, setClassifications] = useState<string[]>([]);
    const [classificationFilters, setClassificationFilters] = useState<string[]>([]);
    const [visibilityFilters, setVisibilityFilters] = useState<string[]>([]);
    // Alphabet Button Group
    const [filterByCharValues, setFilterByCharValues] = useState<string[]>([])
    // Fetch ingredients from query
    const [queryParams] = useSearchParams();
    const [ingredientIsLoading, setIngredientLoading] = useState<boolean>(true);
    // Delete Ingredient
    const [isDeleteIngredientModalVisible, setDeleteIngredientModalVisible] = useState(false);
    //feedbackbanner
    const [showBanner, setShowBanner] = useState(false);
    const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
    const [feedbackText, setFeedbackText] = useState("");
    const handleCloseBanner = () => setShowBanner(false);
    const handleOpenFeedbackPopup = () => setShowFeedbackPopup(true);
    const handleCloseFeedbackPopup = () => setShowFeedbackPopup(false);
    const handleFeedbackChange = (e: React.ChangeEvent<HTMLInputElement>) => setFeedbackText(e.target.value);
    const handleSubmitFeedback = async() => {
        console.log("Feedback submitted:", feedbackText);

        try {
            const response = await sendFeedback(searchedValues,feedbackText, exactSearchResultsArray , exactSearchTokenResultsArray, exactSearchResultsNotFoundArray);
            if (response) {
                message.success(`Feedback Recorded successfully`);
            } else {
                message.error(`Error Recording Feedback 2`);
            }
        } catch (error) {
            message.error(`Error Recording Feedback`);
        } finally {
            setShowFeedbackPopup(false);
            setFeedbackText("");        }
    };

    useEffect(() => {
        if (searchResultsArray.length > 0) {
            setShowBanner(true);
        }
    }, [searchResultsArray, searchValue]);

    const handleCategoryChange = (value: string) => {
        setSelectedType(value);
    };

    useEffect(() => {
        const fetchSearchSuggestions = async () => {
            setIngredientLoading(true);
            try {
                if (searchValue.length > 2) {
                    const searchSug: string[] = [];
                    searchSug.push(searchValue);
                    const searchSuggestions = await searchIngredients(searchSug);
                    let flexibleSearchSuggestions: Ingredient[] = searchSuggestions.flexibleMatchResults[searchValue] || [];

                    flexibleSearchSuggestions = flexibleSearchSuggestions.filter(
                        (ingredient, index, self) =>
                            index === self.findIndex((i) => i.id === ingredient.id)
                    );

                    setSearchSuggestionsArray(flexibleSearchSuggestions);
                }
            } catch (error) {
                console.error('Error fetching search results:', error);
            } finally {
                setIngredientLoading(false);}
        };
        fetchSearchSuggestions();

        // Cleanup function to set the flag to false
        return () => {};
    }, [searchValue]);


    useEffect(() => {
        const fetchSearchResults = async () => {
            setIngredientLoading(true);
            try {
                if (searchedValues.length > 0) {
                    const searchResults = await searchIngredients(searchedValues);

                    setSearchResults(searchResults)

                    const searchResultsArray: Ingredient[] = [];
                    const exactSearchResultsArray: Ingredient[]= [];
                    const exactSearchTokenResultsArray: Ingredient[] = [];
                    const exactSearchResultsNotFoundArray: string[] = [];
                    for (const searchValue of searchedValues) {
                        const exactSearchResults: Ingredient[] = searchResults.exactMatchResults[searchValue] || [];
                        if (!exactSearchResults.length) {
                            const exactSearchTokenResults: Ingredient[] = searchResults.exactMatchTokenResults[searchValue] || [];
                            const limitedExactSearchTokenResults = exactSearchTokenResults.slice(0, 5);
                            exactSearchTokenResultsArray.push(...limitedExactSearchTokenResults);
                            exactSearchResultsNotFoundArray.push(searchValue)
                        }

                        const flexibleSearchResults: Ingredient[] = searchResults.flexibleMatchResults[searchValue] || [];
                        const SearchArray: Ingredient[] = [...exactSearchResults, ...flexibleSearchResults];
                        exactSearchResultsArray.push(...exactSearchResults);
                        searchResultsArray.push(...SearchArray);
                    }

                    setExactSearchResultsArray(exactSearchResultsArray);

                    setExactSearchTokenResultsArray(exactSearchTokenResultsArray);

                    setSearchResultsArray(searchResultsArray);

                    setexactSearchResultsNotFoundArray(exactSearchResultsNotFoundArray);

                }

            } catch (error) {
                console.error('Error fetching search results:', error);
            } finally {
                setIngredientLoading(false);
            }
        };
        fetchSearchResults();
    }, [searchedValues]);

    // Fetch ingredients from API and Cache
    useEffect(() => {
        setIngredientLoading(true);
        getCachedIngredients()
            .then(cachedIngredients => {
                const listOfIngredients = admin ? cachedIngredients : cachedIngredients.filter(ingredient => ingredient.visibility === 'Publish');
                setIngredients(listOfIngredients);
                setIngredientLoading(false);
                // All types in acceptability, creates respective column entry
                const types = new Map<string, ColumnProps<Ingredient>>();
                const classifications = new Set<string>();
                const authorList = new Set<string>();
                const adminColumns = new Map<string, ColumnProps<Ingredient>>();
                cachedIngredients.forEach(({classification, acceptability, createdBy}) => {
                    if(classification && classification.trim().length > 0)
                        classifications.add(classification);
                    if (createdBy && createdBy.trim().length > 0)
                        authorList.add(getName(createdBy));
                    acceptability.forEach(a => {
                        if(a.type) {
                            types.set(a.type, {
                                title: a.type,
                                dataIndex: ACCEPTABILITY,
                                key: a.type,
                                render: (value: Acceptability[]) => {
                                    const acceptability = value.find((v: Acceptability) => v.type == a.type)
                                    return getAcceptabilityIcon(acceptability)
                                }
                            })

                        }
                    })
                });
                adminColumnList.forEach(({columnName, columnMapping}) => {
                    const column: ColumnProps<Ingredient> = {
                        title: columnName,
                        dataIndex: columnName,
                        key: columnMapping,
                        render: (_value, record) => <>{getColumnValue(record, columnMapping)}</>
                    };
                    adminColumns.set(columnName, column);
                })
                setTypeColumns(types);
                setTypes(Array.from(types.keys()));
                // setSelectedType(Array.from(types.keys()));
                setClassifications(Array.from(classifications));
                setClassificationFilters(Array.from(classifications));
                setAuthors(Array.from(authorList));
                setAuthorFilters(Array.from(authorList));
                setVisibilityFilters(visibilities);
                setAdminColumns(adminColumns);
                if(!window.location.href.includes("?ingredients=")) {
                    setSearchedValues([]);
                    setSearchValue("");
                }
                setFilterByCharValues([]);
            });
    }, [location.pathname, window.location.href])

    // Fetch query parameters for ingredients from URL
    useEffect(() => {
        const searchedIngredients = queryParams.get('ingredients');
        setSearchedValues(searchedIngredients?.split(',') || [])
    }, [queryParams])

    // Remaining Columns
    const remainingColumns = admin ? adminColumns : typeColumns;

    // column headers for table
    let columns: ColumnProps<Ingredient>[] = [
        {
            title: 'Ingredients',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            sorter: sortItems,
            render: (value, record) =>
                <>
                    {
                        admin ? (
                            <>
                                <Tag>{record.classification}</Tag>
                                <p>{value}</p>
                                <Button type="link"><Link to={`edit/ingredient/${record.id}`}>Edit</Link></Button>
                                <ConfigProvider
                                    theme={{
                                        token: {
                                            // Seed Token
                                            colorLink : '#00b966',
                                        },
                                    }}
                                >
                                    <Button type="link"><Link
                                        to={`/?ingredients=${record.name}`}>View</Link></Button>
                                </ConfigProvider>
                                <Button type="link" danger onClick={() => {setIngredientDetails(record); setDeleteIngredientModalVisible(true)}}>Delete</Button>
                            </>
                        ) : (
                            <><Tag>{record.classification}</Tag><p>{value}</p></>
                        )
                    }
                </>
        },
        ...Array.from(remainingColumns.values())
    ];

    const [filteredColumns, setFilteredColumns] = useState(columns);

    // Filter column entries by types
    useEffect(() => {
        setFilteredColumns(columns.filter(c => String(c.key) == 'name' || selectedType === String(c.key)));
    }, [selectedType, filterByCharValues, searchedValues])

    const resetFilters = () => {
        setClassificationFilters([]);
    }

    const handleDelete = () => {
        setDeleteIngredientModalVisible(false);
        setSearchedValues([]);
        navigate(HOME_PAGE);
        navigate(0);
    }

    const handleSelectChange = (values: any) => {
        // Update the selected value state
        if (selectedType === undefined) {
            console.log("null");
            message.error("Please select a category to search");
            return null;
        }
        setSearchedValues(values);
        setSearchValue("");
    }

    const tableColumns = admin ? columns : filteredColumns;

    const handleOnSearchValue=(value: string)=>{

        const trimmedValue = value.trim();

        const hasCommaWithNonNumericBefore = /[^0-9]\s*,/;
        const regexToSplit = /(?<!\d),/;

        if (hasCommaWithNonNumericBefore.test(trimmedValue)) {
            const filteredValues = trimmedValue
                .split(regexToSplit)
                .map(v => v.trim())
                .filter(v => v.length > 2);

            if (filteredValues.length > 0) {
                setSearchedValues([...searchedValues, ...filteredValues]);
            }

            setSearchValue("");
            return;
        }
        setSearchValue(value);
    }

    return <>

        <div style={{
            backgroundColor: "#fffbe6",
            border: "1px solid #ffe58f",
            padding: "10px",
            marginBottom: "20px",
            borderRadius: "4px"
        }}>
            <strong>Note:</strong> The ingredient deck checker is an experimental feature and we are gathering feedback.
        </div>

        <div className={"margin-default"}>
            <h3>Check ingredient acceptability for a product</h3>
        </div>
        <div className={"margin-default"}>
            <Typography.Text>{`Select a category to search `}</Typography.Text>
            <Select
              placeholder={`Select category`}
              style={searchFiltersStyle}
              value={selectedType}
              onChange={handleCategoryChange}
              allowClear
            >
                {
                    admin ? authors
                        .sort((a, b) => a.localeCompare(b, undefined, {numeric: true,}))
                        .map(author => <Option key={author}>{author}</Option>)
                      : Array.from(typeColumns.keys())
                        .sort((a, b) => a.localeCompare(b, undefined, {numeric: true,}))
                        .map(type => <Option key={type}>{type}</Option>)
                }
            </Select>
        </div>
        <div className={"margin-default"}>
            <div>
                <SearchBar
                  data={
                      (searchValue.length > 2 ? searchSuggestionsArray : ingredients)
                        .sort((a, b) => a.name.localeCompare(b.name, undefined, {numeric: true,}))
                        .map(ingredient => <Option key={`${ingredient.name}-${ingredient.id}`}
                                                   value={`${ingredient.name}`}>{ingredient.name}</Option>)
                  }
                  searchValue={searchValue}
                  searchedValues={searchedValues}
                  placeholder="Type or paste ingredients separated by a comma to search"
                  onChange={handleSelectChange}
                  onSearch={value => {

                      if (selectedType !== undefined) {
                          handleOnSearchValue(value);
                      } else {
                          message.error("Please select a category to search");
                      }
                  }}
                  onInputKeyDown={e => {
                      if (e.key === 'Enter') {

                          e.stopPropagation()

                          if (selectedType === undefined) {
                              console.log("null");
                              message.error("Please select a category to search");
                              return null;
                          }
                          // @ts-ignore
                          if (e.target.value.length > 0) {
                              // @ts-ignore
                              if (searchValue.trim().length > 2 && selectedType !== undefined) {
                                  setSearchedValues([...searchedValues, searchValue.trim()]);
                              }
                              setSearchValue("");
                          }
                      }
                  }}
                  tagRender={props => {
                      const {value, closable, onClose} = props;
                      const [tagColor, setTagColor] = useState(PRIMARY_COLOR); // Initial state as primary color
                      const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
                          event.preventDefault();
                          event.stopPropagation();
                      };

                      return (
                        <Tag
                          color={tagColor}
                          onMouseDown={onPreventMouseDown}
                          closable={closable}
                          onClose={onClose}
                        >
                            {value}
                        </Tag>
                      );
                  }
                  }
                />
                <div className={"margin-default"}>
                    <TermsCard/>
                </div>
            </div>
        </div>

        <div style={{
            backgroundColor: "#f0f8ff",
            padding: "10px",
            marginBottom: "10px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        }}>
            <a
              href="https://forms.office.com/Pages/ResponsePage.aspx?id=YX-gMUrJmE-jBFdL_CvhuuTkObczpJJFiYEXgb_FMg5UMzMzU0hWRElRUVRZMjlIRTdUQ0pNVjVFQS4u"
              target="_blank"
              rel="noopener noreferrer"
              style={{color: "#1890ff", textDecoration: "none"}}
            >
                Tell us your feedback or report missing ingredient
            </a>
        </div>

        {showFeedbackPopup && (
          <Modal
            title="Provide Your Feedback"
            visible={showFeedbackPopup}
            onOk={handleSubmitFeedback}
            onCancel={handleCloseFeedbackPopup}
          >
              <Input
                placeholder="Type your feedback here..."
                value={feedbackText}
                onChange={handleFeedbackChange}
              />
          </Modal>
        )}

        <div>
            <h3>Summary Table:</h3>
        </div>
        <SummaryTable
          data={
              filterIngredient(
                searchedValues.length > 0 ? exactSearchResultsArray : [],
                classificationFilters,
                authorFilters,
                visibilityFilters,
                selectedType ? selectedType : "Food"
              )}
          unreviewedData={exactSearchResultsNotFoundArray}
          closeMatchData={
              filterIngredient(
                searchedValues.length > 0 ? exactSearchTokenResultsArray : [],
                classificationFilters,
                authorFilters,
                visibilityFilters,
                selectedType?.length ? selectedType : "Food"
              )}
          category={selectedType ? selectedType : "Food"}
          columns={tableColumns}
          loading={ingredientIsLoading}/>

        <div className={"margin-default"}>
            <BottomTermsCard/>
        </div>
        {
          ingredientDetails !== undefined && (
            <Delete
              open={isDeleteIngredientModalVisible}
              onConfirm={handleDelete}
              onCancel={() => setDeleteIngredientModalVisible(false)}
              type={INGREDIENT}
              id={ingredientDetails?.id}
            />
          )
        }
    </>
}

export default SummaryContainer;